import React, { useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";
import tnc_desktop_en from "../../images/tnc-desktop-en.png"
import tnc_mobile_en from "../../images/tnc-mobile-en.png"
import tnc_desktop_jp from "../../images/tnc-desktop-jp.png"
import tnc_mobile_jp from "../../images/tnc-mobile-jp.png"

const CancellationPolicy = () => {
  const page_name = "cms-cancellation-policy";
  const dispatch = useDispatch();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  // useEffect(() => {
  //   if (lang === null) return;
  //   i18n.changeLanguage(lang.toLowerCase());
  // }, []);

  return (
    <FrontLayout>
    {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-cancellation d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5 px-3 px-md-0">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Pricing")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Cancellation.Policy")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Cancellation.Policy.Intro")} */}
            </p>
          </div>
        </div>
      </div>

      {/* <!-- cancellation policy --> */}

      
      
      <div className="container-md col-md-11 col-lg-9 mx-auto my-5 pt-lg-4 pb-lg-5 px-3 px-md-0">
        <div className="d-none d-md-block">
          <div className="d-flex justify-content-center">
          <img src={   lang === "EN" ? tnc_desktop_en : tnc_desktop_jp} alt="" className="w-100" />
          </div>
        </div>
        <div className="d-block d-md-none">
        <div className="d-flex justify-content-center">
        <img src={ lang === "EN" ?  tnc_mobile_en : tnc_mobile_jp} alt="" className="w-100" />
        </div>
      </div>
        {/* <div className="row">
          <div
            className="col col-lg-10 offset-lg-1"
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content4
                  : cmspage?.page_content_jp.content4,
            }}
          >
          </div>
        </div> */}
      </div>
    </FrontLayout>
  );
};

export default CancellationPolicy;

import React, { Fragment, useEffect, useState, useMemo } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import ByTheHourForm from "../layout-new-theme/form-card/ByTheHourForm";
import SpecifiedDestinationForm from "../layout-new-theme/form-card/SpecifiedDestinationForm";
import { useDispatch, useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import {
  addByTheHourFormToState,
  addSpecifiedDestinationFormToState,
  getAvailableVehicle
} from "../../actions/admin/booking";
import {
  checkTokyoAvailability,
  getTravelDateWithPickupTime,
  getTotal,
  isDateInMultiplierZone,
  getKey,
  getTotalPaxCount,
  getTotalLuggageCountArray,
  getTotalPaxCountArray,
} from "../../utils/Calculator";
import {
  getAvailableVehicles,
} from "../../actions/admin/booking";
import moment from "../../utils/customMoment";
import { isAfter } from "date-fns";
import { getSetting } from "../../actions/admin/settings";
import VehicleTypeCard from "../layout-new-theme/cards/VehicleTypeCard";
import CarSelection from "../layout-new-theme/cards/CarSelection";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import _ from 'lodash';
import LoginModal from "../auth/LoginModal";
import AlertModal from "./AlertModal";
import back_icon_edit_booking from "../../images/back_icon_edit_booking.svg";
import LoadingSpinner from "./LoadingSpinner";
import Spinner from "../layout/Spinner";

const Discover = ({ alert, isAuthenticated, isEditBooking = false, keyBook = "" }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("byTheHour");
  const { byTheHourFormData, specifiedDestinationFormData, cart } = useSelector(
    (state) => state.booking
  );
  const settings = useSelector((state) => state.setting);
  const { setting, lang } = settings;
  const [isloading, setIsloading] = useState(false);
  const [vehicleTypeNames, setVehicleTypeNames] = useState([]);
  const [garageArivalTime, setgarageArivalTime] = useState("");
  // const [totalTime, setTotalTime] = useState(0);
  const [cutomerChangedDropOffTimeOnly, setcutomerChangedDropOffTimeOnly] = useState("");
  const [activatedPackages, setActivatedPackages] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [clickedVehicleType, setClickedVehicleType] = useState("");
  const [showDetailsPannels, setShowDetailsPannels] = useState(false);
  const [sfd, setSfd] = useState({
    pickuplocation: null,
    dropofflocations: [],
    traveldate: "",
    pickuptime: "",
    dropofftime: "",
    pax: null,
    flightNo: "",
    isFromAirport: null,
    isToAirport: null,
    isAirportTransfer: null,
    byTheHourBook: false,
    lugage: null,
    flightTracking: null,
    travelArea: null,
  });
  const [note, setNote] = useState("");
  const [cartID, setCartID] = useState(null);
  const [isEquationApplied, setIsEquationApplied] = useState(false);

  //state for handle book more than 9 people
  const [bookingInfo, setBookingInfo] = useState([]);
  const [selectedManyVehicleType, setSelectedManyVehicleType] = useState([]);
  const [clickedManyVehicleType, setClickedManyVehicleType] = useState([]);
  const [countCarToChoose, setCountCarToChoose] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [formdatavalidation, setFormdatavalidation] = useState({
    pickuplocation: true,
    dropofflocations: true,
    traveldate: true,
    pickuptime: true,
    pax: true,
    flightNumber: true,
    duration: true,
    luggage: null,
  });

  //new states
  const [totalNightHours, setTotalNightHours] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [startFromGarage, setStartFromGarage] = useState(null);
  const [travelDateWithPickupTime, setTravelDateWithPickupTime] = useState(null);
  const [customerDropoffDateTime, setCustomerDropoffDateTime] = useState(null);
  const [garageArrivalTime, setGarageArrivalTime] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [garage, setGarage] = useState(null);
  const [additionalTime, setAdditionalTime] = useState(0);
  const [breakTime, setBreakTime] = useState(0);
  const [flightTrackingTime, setFlightTrackingTime] = useState(null);
  const [usageDuration, setUsageDuration] = useState(0);
  const [billedDuration, setBilledDuration] = useState(0);
  const [appliedPackage, setAppliedPackage] = useState(null);


  /**
   * * Set the Cart ID when the page is loading. This should only happen IF THE CART IS NOT EMPTY! The Cart ID is used to identify bookings that
   * were made as part of the same purchase/ cart. So, if a cart has 3 bookings in it that are paid together, then they will all share the same cart ID.
   * The cart behaves as a "container" object for the bookings.
   */
  useEffect(() => {
    if (cart?.length === 0 || cart === null) return;
    setCartID(cart[0].randomId);
  }, [cart]);

  /**
   * * Set any active airport transfer packages to the state.
   */
  useEffect(() => {
    if (packageList === null) return;
    if (packageList?.length === 0) return;

    setActivatedPackages([packageList[0]]);
  }, [packageList]);

  /**
   * * Reset all the form values to their initial / empty values.
   */
  const resetFormValues = () => {
    setShowDetailsPannels(false);
    setSfd({
      pickuplocation: null,
      dropofflocations: [],
      traveldate: "",
      pickuptime: "",
      dropofftime: "",
      pax: null,
      flightNo: "",
      isToAirport: null,
      isFromAirport: null,
      isAirportTransfer: null,
    });
    setcutomerChangedDropOffTimeOnly("");
  };

  /**
   * * Set the selected tab (By The Hour Destination, Specified Destination) based on which tab was active during the redirect from the
   * home-page (if the booking was started there)
   * ? By The Hour form tab will be selected
   */
  const byTheHourFormAvailableVehicle = (byTheHourFormData) => {
    setShowDetailsPannels(true);
    setTabName("byTheHour");
    setSfd(byTheHourFormData);
    dispatch(addByTheHourFormToState(byTheHourFormData));
    dispatch(addSpecifiedDestinationFormToState(null));
    checkAvailability(byTheHourFormData);
    if (isEditBooking) {
      seteditbookingStep(2);
    }
  };

  /**
   * * Set the selected tab (By The Hour Destination, Specified Destination) based on which tab was active during the redirect from the
   * home-page (if the booking was started there)
   * ? Specified Destination form tab will be selected
   */
  const specifiedDestinationFormAvailableVehicle = (
    specifiedDestinationFormData
  ) => {
    setShowDetailsPannels(true);
    setTabName("specifiedDestination");
    setSfd(specifiedDestinationFormData);
    dispatch(addByTheHourFormToState(null));
    dispatch(addSpecifiedDestinationFormToState(specifiedDestinationFormData));
    checkAvailability(specifiedDestinationFormData);
    if (isEditBooking) {
      seteditbookingStep(2);
    }
  };

  /**
   * * Set the selected tab (By The Hour, Specified Destination) based on which tab was active during the redirect from the
   * home-page (if the booking was started there)
   * * Set state varibles
   * ? When the parameters change, this funcion will be fired
   * @param byTheHourFormData
   * @param specifiedDestinationFormData
   */
  useEffect(() => {
    if (!settings) dispatch(getSetting());
    if (byTheHourFormData) {
      if (!isEditBooking) {
        byTheHourFormAvailableVehicle(byTheHourFormData);
      }
    } else if (specifiedDestinationFormData) {
      if (!isEditBooking) {
        specifiedDestinationFormAvailableVehicle(specifiedDestinationFormData);
      }
    }
  }, [byTheHourFormData, specifiedDestinationFormData]);

  useEffect(() => {
    if (isEditBooking == false) {
      if (showDetailsPannels) {
        const element = document.getElementById("scroll01");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [showDetailsPannels]);

  /**
   * * This function will be called when the "Search available vehicles" button is clicked by the user
   * * After that, the listed state varibles will be set to their initial vaues
   */
  const availabilityValueReset = () => {
    setShowDetailsPannels(false);
    setActivatedPackages([]);
    setPackageList([]);
    setClickedVehicleType(null);
    setVehicleTypeNames([]);
    setBookingInfo([]);
    setSelectedManyVehicleType([]);
    setClickedManyVehicleType([]);
  };

  /**
   * * Main function here. Fire when the "Search available vehicles" button is clicked by the user.
   * ? sfd - Selected Form Data
   * @param sfd
   */
  const checkAvailability = async (sfd) => {
    setIsloading(true);
    availabilityValueReset();
    const buffer_time = setting.buffertime !== null ? setting.buffertime : 0;

    const totalPax = getTotalPaxCount(sfd.pax);

    if (
      !sfd.traveldate ||
      !sfd.pickuptime ||
      !sfd.pickuplocation ||
      sfd.dropofflocations.length === 0 ||
      totalPax === 0
    ) {
      setIsloading(false);
    } else {
      setVehicleTypeNames([]);
      setgarageArivalTime(null);

      const { travel_date_with_pickup_time } = getTravelDateWithPickupTime(sfd);

      // Set the buffer time to current time (buffer time can be specified in hours by the TLF admins in the settings page of the Admin Panel)
      // The buffer time determines how many hours ahead a booking can be. For example, if the buffer time is set to 12 hours, any booking must
      // be at least 12 hours in the future, from the current time specified here
      const date_with_buffer_time = moment()
        .tz("Asia/Tokyo")
        .add(buffer_time, "hours");

      // Validate the travel date and time after setting the buffer time. This must evaluate to a true value in order to continue the function
      var isafter = isAfter(
        travel_date_with_pickup_time._d,
        date_with_buffer_time._d
      );

      if (isafter === false) {
        setFormdatavalidation({
          ...formdatavalidation,
          pickuptime: false,
        });
      } else {
        var reqParamsAvailableVehicle = {
          pickupLocation: sfd.pickuplocation,
          dropoffLocations: sfd.dropofflocations,
          bookingType: sfd.byTheHourBook ? "byTheHour" : "specifiedDestination",
          travelDate: sfd.traveldate,
          pickupTime: sfd.pickuptime,
          pax: sfd.pax,
          luggage: sfd.lugage,
          flightTracking: (sfd.flightTracking || sfd.isAirportTransfer),
        }

        if (sfd.byTheHourBook) {
          reqParamsAvailableVehicle = {
            ...reqParamsAvailableVehicle,
            usageDuration: sfd.duration,
            travelArea: sfd.travelArea
          }
        }

        const availableVehicle = await getAvailableVehicle(reqParamsAvailableVehicle);

        if (availableVehicle.code !== 200 || availableVehicle.desc !== "SUCCESS" || availableVehicle.data === null) {
          setmodalAlertText(availableVehicle.error ?? "An error occurred while fetching available vehicles.");
          setShowModalAlert(true);
          setIsloading(false);
        }

        const {
          total_time,
          totalNightHours,
          startFromGarage,
          travelDateWithPickupTime,
          customerDropoffDateTime,
          garageArrivalTime,
          totalDistance,
          garage,
          additionalTime,
          breakTime,
          flightTrackingTime,
          usageDuration,
          billedDuration,
          appliedPackage,
          availableVehicleTypes,
        } = availableVehicle.data;

        setTotalNightHours(totalNightHours);
        setTotalTime(total_time);
        setStartFromGarage(startFromGarage);
        setTravelDateWithPickupTime(travelDateWithPickupTime);
        setCustomerDropoffDateTime(customerDropoffDateTime);
        setGarageArrivalTime(garageArrivalTime);
        setTotalDistance(totalDistance);
        setGarage(garage);
        setAdditionalTime(additionalTime);
        setBreakTime(breakTime);
        setFlightTrackingTime(flightTrackingTime);
        setUsageDuration(usageDuration);
        setBilledDuration(billedDuration);
        setAppliedPackage(appliedPackage);

        setCountCarToChoose(1);
        //  The TLF team can set the "total trip time" in the Admin Panel settings. Bookings CANNOT exceed this time
        // If the time from the garage, back to the garage is longer than this "total trip time", it will throw an error.

        //validation total trip time
        // if (!sfd.byTheHourBook) {
        //   if (Number(total_time / 60) > Number(setting.totaltriptime)) {
        //     setmodalAlertText("For bookings longer than " + setting.totaltriptime + " hours, please contact our sales team.");
        //     setShowModalAlert(true);
        //     setIsloading(false);
        //     return;
        //   }
        // }

        if (availableVehicleTypes.length === 0) {
          setmodalAlertText("We have no available vehicles on the selected day.");
          setShowModalAlert(true);
          setIsloading(false);
          return;
        }

        const sortedArray = availableVehicleTypes.sort((a, b) => a.baseprice - b.baseprice);

        setVehicleTypeNames(sortedArray);

        setShowDetailsPannels(true);

        const element = document.getElementById("scroll01");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }


      }
    }
    setIsloading(false);
  };

  useEffect(() => {
    setFormdatavalidation({
      pickuplocation: true,
      dropofflocations: true,
      traveldate: true,
      pickuptime: true,
      pax: true,
      flightNumber: true,
      duration: true,
      luggage: null,
    });
  }, [tabName]);

  /**
   * * Set the selected tab (Single Destination, Multiple Destination, Airport Transfer, By The Hour) in the UI. Check the tabName parameter value.
   */
  const getTab = () => {
    if (tabName === "byTheHour")
      return (
        <ByTheHourForm
          byTheHourForm={byTheHourFormData}
          checkByTheHourFormAvailableVehicle={byTheHourFormAvailableVehicle}
          availabilityValueReset={availabilityValueReset}
          formValidationData={formdatavalidation}
          isLoading={isloading}
        />
      );
    if (tabName === "specifiedDestination")
      return (
        <SpecifiedDestinationForm
          specifiedDestinationForm={specifiedDestinationFormData}
          checkSpecifiedDestinationFormAvailableVehicle={
            specifiedDestinationFormAvailableVehicle
          }
          cutomerChangedDropOffTimeOnly={cutomerChangedDropOffTimeOnly}
          availabilityValueReset={availabilityValueReset}
          formValidationData={formdatavalidation}
          isLoading={isloading}
        />
      );
  };

  /**
   * * Set equation values and state variables
   * * on click event => when the vehicle type is selected / clicked
   * * hourlyprice = 30 minute rate for the selected vehicle type (this can be specified by the TLF team when creating a vehicle type in the Admin Panel)
   * @param vehicle_type - Selected vehicle type
   */
  const applyToTheEquation = (vehicle_type, index = null) => {
    var newSelectedVehicleType;
    var newClickedManyVehicleType;
    var newBookingInfo;

    if (
      selectedManyVehicleType.length === 0 &&
      selectedManyVehicleType[index] === undefined
    ) {
      newSelectedVehicleType = [...selectedManyVehicleType, vehicle_type];
    } else {
      newSelectedVehicleType = [...selectedManyVehicleType];
      newSelectedVehicleType[index] = vehicle_type;
    }

    setSelectedManyVehicleType(newSelectedVehicleType);

    const totalAmount = vehicle_type.price;

    var childSeatList = [
      {
        childSeatType: "Infant",
        childSeatCount: 0,
      },
      {
        childSeatType: "Toddler",
        childSeatCount: 0,
      },
      {
        childSeatType: "Child",
        childSeatCount: 0,
      },
    ];

    if (getTotalPaxCount(sfd.pax) <= 9) {
      var paxList = [
        {
          paxtype: "Adult",
          paxcount: sfd.pax.adult,
        },
        {
          paxtype: "Child",
          paxcount: sfd.pax.child,
        },
        {
          paxtype: "Toddler",
          paxcount: sfd.pax.toddler,
        },
        {
          paxtype: "Baby",
          paxcount: sfd.pax.baby,
        },
      ];

      var luggageList = [
        {
          luggagetype: "CHECK-IN SIZE",
          luggagecount: sfd.lugage.checkinSize,
        },
        {
          luggagetype: "CABIN SIZE",
          luggagecount: sfd.lugage.cabinSize,
        },
      ];
    } else {
      var paxList = null;
      var luggageList = null;
    }
    // // -------------------------------------------------------------------------------------------------------------------------

    if (selectedManyVehicleType.length === 0 && selectedManyVehicleType[index] === undefined) {
      newClickedManyVehicleType = [...clickedManyVehicleType, vehicle_type.typename]

      newBookingInfo = [...bookingInfo, {
        vehicleTypeName: vehicle_type.typename,
        vehicleTypeID: vehicle_type._id,
        pax: paxList,
        luggage: luggageList,
        baseprice: vehicle_type.baseprice,
        hourlyprice: vehicle_type.hourlyprice,
        tollFare: vehicle_type.tollFare,
        travelAreaPrice: vehicle_type.travelAreaPrice,
        longDistanceSurcharge: vehicle_type.longDistanceSurcharge,
        total: totalAmount,
        childSeat: childSeatList,
        childSeatPrice: 0,
        travelArea: sfd.travelArea,
      }]


    } else {
      newClickedManyVehicleType = [...clickedManyVehicleType];
      newClickedManyVehicleType[index] = vehicle_type.typename;

      newBookingInfo = [...bookingInfo];
      newBookingInfo[index] = {
        vehicleTypeName: vehicle_type.typename,
        vehicleTypeID: vehicle_type._id,
        pax: paxList,
        luggage: luggageList,
        baseprice: vehicle_type.baseprice,
        hourlyprice: vehicle_type.hourlyprice,
        tollFare: vehicle_type.tollFare,
        travelAreaPrice: vehicle_type.travelAreaPrice,
        longDistanceSurcharge: vehicle_type.longDistanceSurcharge,
        total: totalAmount,
        childSeat: childSeatList,
        childSeatPrice: 0,
        travelArea: sfd.travelArea,
      }
    }


    setClickedManyVehicleType(newClickedManyVehicleType);
    setBookingInfo(newBookingInfo);

    if (getTotalPaxCount(sfd.pax) <= 9) {
      setIsEquationApplied(true);
    }
  };

  const getCurrentMaxPassenger = useMemo(() => {
    const updatedSfd = _.cloneDeep(sfd);
    var bookedPax = updatedSfd.pax;

    if (bookingInfo.length === 0) {
      return bookedPax;
    } else {
      bookingInfo.map((item, index) => {
        if (item !== null) {
          const paxArray = item.pax;
          if (paxArray !== null) {
            for (let ind = 0; ind < paxArray.length; ind++) {
              let key = paxArray[ind].paxtype.toLowerCase();
              if (bookedPax[key] !== undefined) {
                bookedPax[key] -= paxArray[ind].paxcount;
              }
            }
          }
        }
      });
      return bookedPax;
    }
  }, [bookingInfo]);

  const getCurrentMaxLugage = useMemo(() => {
    const updatedSfd = _.cloneDeep(sfd);

    var bookedLugage = updatedSfd.lugage;

    if (bookingInfo.length === 0) {
      return bookedLugage;
    } else {
      bookingInfo.map((item, index) => {
        if (item !== null) {
          const lugageArray = item.luggage;
          if (lugageArray !== null) {
            for (let ind = 0; ind < lugageArray.length; ind++) {
              if (lugageArray[ind].luggagetype === "CHECK-IN SIZE") {
                var key = "checkinSize";
              } else if (lugageArray[ind].luggagetype === "CABIN SIZE") {
                var key = "cabinSize";
              }

              if (bookedLugage[key] !== undefined) {
                bookedLugage[key] -= lugageArray[ind].luggagecount;
              }
            }
          }
        }
      });
      return bookedLugage;
    }
  }, [bookingInfo]);

  const getClickedManyVehicleType = useMemo(() => {
    return clickedManyVehicleType;
  }, [clickedManyVehicleType]);

  /**
   * * Set pax values and state variables
   * * on click proceed event => after the vehicle type is selected / clicked
   */
  const applyPaxAndLugageToTheEquation = (pax, lugage, index) => {
    var paxList = [
      {
        paxtype: "Adult",
        paxcount: pax.adult,
      },
      {
        paxtype: "Child",
        paxcount: pax.child,
      },
      {
        paxtype: "Toddler",
        paxcount: pax.toddler,
      },
      {
        paxtype: "Baby",
        paxcount: pax.baby,
      },
    ];

    var luggageList = [
      {
        luggagetype: "CHECK-IN SIZE",
        luggagecount: lugage.checkinSize,
      },
      {
        luggagetype: "CABIN SIZE",
        luggagecount: lugage.cabinSize,
      },
    ];

    const editedBookingInfo = bookingInfo;

    if (editedBookingInfo[index]) {
      editedBookingInfo[index].pax = paxList;
      editedBookingInfo[index].luggage = luggageList;

      setBookingInfo(editedBookingInfo);

      const totalPax = bookingInfo.map((item) => item["pax"]);
      const totalLugage = bookingInfo.map((item) => item["luggage"]);

      const totalPaxCount = getTotalPaxCountArray(totalPax);
      const totalLugageCount = getTotalLuggageCountArray(totalLugage);

      if (totalPaxCount < getTotalPaxCount(sfd.pax)) {
        if (
          bookingInfo[countCarToChoose - 1] !== undefined &&
          bookingInfo[countCarToChoose - 1].pax !== null
        ) {
          setCountCarToChoose(countCarToChoose + 1);
        }
      } else {
        // setIsEquationApplied(true);
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    const element = document.getElementById("scroll" + countCarToChoose);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [countCarToChoose]);

  useEffect(() => {
    if (isEquationApplied) {
      addTocart(true);
    }
  }, [isEquationApplied]);

  /**
   * * Add item to the cart. Data gets added to the database at this point (if the cart expires with a booking in it, the "isAbandoned" flag gets
   * set to true). Then the booking gets shown in the "Abandoned Carts" view in the Admin Panel (for tracking purposes).
   * @param isCart - bool values. check if true and redirect to the cart page
   */
  const addTocart = async (isCart) => {
    // formValidation();
    const totalPax = getTotalPaxCount(sfd.pax);
    if (
      !sfd.traveldate ||
      !sfd.pickuptime ||
      !sfd.pickuplocation ||
      sfd.dropofflocations.length === 0 ||
      totalPax === 0
    ) {
      setIsloading(false);
    } else {
      // check total time take
      const trip_total_time = Number(bookingInfo[0].totalhours);
      if (trip_total_time / 60 > Number(setting.totaltriptime)) {
        setmodalAlertText("For bookings longer than 16 hours, please contact our sales team.");
        setShowModalAlert(true);
        setIsloading(false);
        return;
      }
      setIsloading(true);

      let appliedVehicle = [];
      let updatedBookingInfo = [...bookingInfo];

      await Promise.all(
        bookingInfo.map(async (i, ind) => {
          const availableVehicles = await getAvailableVehicles(
            startFromGarage,
            garageArrivalTime,
            i.vehicleTypeName
          );

          if (availableVehicles.length > 0) {
            await availableVehicles.map(async (itm) => {
              if (!appliedVehicle.includes(itm._id)) {
                if (updatedBookingInfo[ind].vehicle === undefined) {
                  appliedVehicle.push(itm._id);
                  updatedBookingInfo[ind] = {
                    ...updatedBookingInfo[ind],
                    vehicle: itm._id,
                  };
                }
              }
            });
          } else {
            // alert.show(
            //   "There are no vehicles available at the selected date and time.",
            //   { type: types.SUCCESS }
            // );
            setmodalAlertText(
              "There are no vehicles available at the selected date and time."
            );
            setShowModalAlert(true);
          }
        })
      );

      setBookingInfo(updatedBookingInfo);

      setIsloading(false);

      const note_arr = [{ note: note }];

      var paxList = [
        {
          paxtype: "Adult",
          paxcount: sfd.pax.adult,
        },
        {
          paxtype: "Child",
          paxcount: sfd.pax.child,
        },
        {
          paxtype: "Toddler",
          paxcount: sfd.pax.toddler,
        },
        {
          paxtype: "Baby",
          paxcount: sfd.pax.baby,
        },
      ];

      var luggagelist = [
        {
          luggagetype: "CHECK-IN SIZE",
          luggagecount: sfd.lugage.checkinSize,
        },
        {
          luggagetype: "CABIN SIZE",
          luggagecount: sfd.lugage.cabinSize,
        },
      ];

      var meetAssistList = [
        {
          meetAssistType: "arrival",
          meetAssistName: "none",
          meetAssistPrice: 0,
        },
        {
          meetAssistType: "departure",
          meetAssistName: "none",
          meetAssistPrice: 0,
        },
      ];

      if (cartID === null) {
        var randomchar =
          Math.floor(Math.random() * 16777215).toString(16) +
          Math.floor(Math.random() * 1000 + 1);
        setCartID(randomchar);
      }

      // Set cart item added time all set to initial added time

      var addedTime = moment();
      if (cart?.length > 0) {
        addedTime = moment(cart[0].date);
      }

      const cart_itm = {
        key: isEditBooking ? keyBook : getKey(),
        randomId: cartID === null ? randomchar : cartID,
        travelDate: sfd.traveldate,
        pickupTime: sfd.pickuptime,
        pickupDateTime: travelDateWithPickupTime.toString(),
        customerDropoffDateTime: customerDropoffDateTime.toString(),
        startFromGarage: startFromGarage,
        garageArrivalTime: garageArrivalTime,
        pickupLocation: sfd.pickuplocation,
        destinations: sfd.dropofflocations,
        totalDistance: totalDistance,
        garage: garage,
        paxList,
        luggagelist,
        bookingType: sfd.byTheHourBook ? "byTheHour" : "specifiedDestination",
        additionalTime: additionalTime,
        breakTime: breakTime,
        flightTrackingTime: flightTrackingTime,
        usageDuration: usageDuration,
        billedDuration: billedDuration,
        isAirportTransfer: sfd.isAirportTransfer === undefined ? false : sfd.isAirportTransfer,
        isFromAirport: sfd.isFromAirport === undefined ? false : sfd.isFromAirport,
        isToAirport: sfd.isToAirport === undefined ? false : sfd.isToAirport,
        isStationTransfer: sfd.isStationTransfer === undefined ? false : sfd.isStationTransfer,
        isFromStation: sfd.isFromStation === undefined ? false : sfd.isFromStation,
        isToStation: sfd.isToStation === undefined ? false : sfd.isToStation,
        meetAssist: meetAssistList,
        isManyPeople: getTotalPaxCount(sfd.pax) > 9 ? true : false,
        package: appliedPackage,
        bookingInfo: updatedBookingInfo,
        date: addedTime,
        passengerPronounced: "Mr.",
        note_arr,
        byTheHourBook: sfd.byTheHourBook,
        duration: sfd.duration,
        byTheHourPax: sfd.byTheHourPax,
        byTheHourLugage: sfd.byTheHourLugage,
      };


      if (isCart === true) {
        const items = [];

        cart_itm.bookingInfo.map((bi, ind) => {
          const item = {
            index: ind,
            item_id: bi.vehicleTypeID,
            item_name: bi.vehicleTypeName,
            item_brand: "Toyota",
            affiliation: "Flywire",
            coupon: "",
            discount: 0,
            item_category: cart_itm.isAirportTransfer ? 'Airport Transfer' : cart_itm.bookingType === "byTheHour" ? 'By The Hour' : 'Specified Destination',
            item_category2:
              cart_itm.destinations.length === 1
                ? cart_itm.destinations[0].place_id
                : cart_itm.destinations[cart_itm.destinations.length - 1]
                    .place_id,
            price: bi.total,
            quantity: 1,
          };

          items.push(item);
        });

        const ecommerceData = {
          items: items,
        };

        const dataToPush = {
          event: "select_item",
          ecommerce: ecommerceData,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataToPush);
        if(!isEditBooking){

        history.push("/booking-confirmation", {
          cart_item: JSON.stringify(cart_itm),
        });
        }else{
          history.push('/booking-confirmation', { cart_item: JSON.stringify(cart_itm), isEditBooking: true, keyBook: keyBook });
        }
      } else {
        window.location.reload(false);
      }
    }
  };

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertText, setmodalAlertText] = useState("");

  const handleOpenModalAlert = (item) => {
    setShowModalAlert(true);
  };

  const handleCloseModalAlert = () => {
    setShowModalAlert(false);
  };

  const [editbookingStep, seteditbookingStep] = useState(1);
  return (
    <>

      <AlertModal
        handleClose={handleCloseModalAlert}
        show={showModalAlert}
        text={modalAlertText}
      />
      {!isEditBooking ? (
        <div
          className={`container-fluid  mx-0 px-0  ${
            tabName === "byTheHour"
              ? "hero-image-home-3-byTheHour"
              : "hero-image-home-3-specifiedDestination"
          }`}
        >
          <div className="container px-md-3 custom-pt-8 d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-lg-6  d-flex align-items-start home-title">
               <h1
                className="fw-700 text-start mb-3 mb-lg-5 mt-4 mt-md-0 fs-22-mobile">
                  {t("Book.Your.Journey")}
                </h1>
              </div>
              <div className="col-lg-6 mt-4 mt-md-0 px-0 px-md-2">
                {/* <!-- select tab btns --> */}
                <div className="col">
                  <ul className="nav nav-custom flex-md-row  text-center ">
                    <li className={`nav-item py-md-0 col-6`}>
                      <div
                        className={`nav-link nav-link-form px-3 px-lg-5  d-flex justify-content-center align-items-center  ${
                          tabName === "byTheHour" ? "active" : ""
                        }`}
                        onClick={() => {
                          resetFormValues();
                          setTabName("byTheHour");
                        }}
                        role={"button"}
                      >
                        <span className="d-none d-md-block">
                          {t("ByTheHour.Destinations.Mobile")}
                        </span>
                        <span className="d-block d-md-none">
                          {t("ByTheHour.Destinations.Mobile")}
                        </span>
                      </div>
                    </li>
                    <li className={`nav-item py-md-0 col-6`}>
                      <div
                        className={`nav-link nav-link-form px-3 px-lg-5 d-flex justify-content-center align-items-center   ${
                          tabName === "specifiedDestination" ? "active" : ""
                        }`}
                        onClick={() => {
                          resetFormValues();
                          setTabName("specifiedDestination");
                        }}
                        role={"button"}
                      >
                        {t("Specified.Destinations")}
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <!-- form part --> */}
                {getTab()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>

          <div className="col">
            <div className="d-flex mb-4 align-items-center">
              {editbookingStep == 2 && (
                <img
                  src={back_icon_edit_booking}
                  className="me-3"
                  alt="Back Icon"
                />
              )}
              <p className="fs-22 noto m-0">
                {editbookingStep == 1 ? "Edit Booking" : "Select Vehicle"}
              </p>
            </div>
            {editbookingStep == 1 && (
              <ul className="nav nav-custom flex-md-row  text-center ">
                <li className={`nav-item py-md-0 col-6`}>
                  <div
                    className={`nav-link nav-link-form px-3 px-lg-5  d-flex justify-content-center align-items-center  ${
                      tabName === "byTheHour" ? "active" : ""
                    }`}
                    onClick={() => {
                      resetFormValues();
                      setTabName("byTheHour");
                    }}
                    role={"button"}
                  >
                    <span className="d-none d-md-block">
                      {t("ByTheHour.Destinations.Mobile")}
                    </span>
                    <span className="d-block d-md-none">
                      {t("ByTheHour.Destinations.Mobile")}
                    </span>
                  </div>
                </li>
                <li className={`nav-item py-md-0 col-6`}>
                  <div
                    className={`nav-link nav-link-form px-3 px-lg-5 d-flex justify-content-center align-items-center   ${
                      tabName === "specifiedDestination" ? "active" : ""
                    }`}
                    onClick={() => {
                      resetFormValues();
                      setTabName("specifiedDestination");
                    }}
                    role={"button"}
                  >
                    {t("Specified.Destinations")}
                  </div>
                </li>
              </ul>
            )}
          </div>

          {/* <!-- form part --> */}
          {editbookingStep == 1 && getTab()}
        </>
      )}

      {/* </LoadingOverlay> */}
      {/* <!-- section 2 end --> */}

      {editbookingStep == 2 && !showDetailsPannels && <div className="row text-center">
          <Spinner />
        </div> }
      {showDetailsPannels && (
        <Fragment>
          {/* <!-- section 1 select vehicle --> */}
          {getTotalPaxCount(sfd.byTheHourBook ? sfd.pax : sfd.pax) <= 9 ? (
            <div
              className={`container-fluid  ${!isEditBooking && "py-5"} px-0`}
              id="scroll01"
            >
              {!isEditBooking && (
                <div className="row mb-3 d-flex align-items-center">
                  <div className="col px-0">
                    <hr />
                  </div>
                  <div className="col-auto text-center">
                    <h3>{t("Discover.Heading.Three")}</h3>
                  </div>
                  <div className="col px-0">
                    <hr />
                  </div>
                </div>
              )}
              {/* <!-- select vehicle heading end --> */}
              <div
                className={`  ${
                  !isEditBooking && "container px-md-3 mt-4 mt-lg-3 pt-lg-5"
                }`}
              >
                {packageList.length > 0 && (
                  <div className="row mb-3 align-items-center justify-content-center">
                    <div className="col">
                      <div className="card">
                        <div
                          className="card-body bg-gold-light-100 border border-0"
                          style={{ padding: "0.5rem" }}
                        >
                          <p className="card-text font-weight-light text-center">
                            {lang == "EN"
                              ? `${packageList[0].packagename} is applied`
                              : `「${packageList[0].packagenameJP}」 のパッケージに該当します`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row gy-4 mb-3">
                  {vehicleTypeNames.map((vt, i) => (
                    <>
                      <VehicleTypeCard
                        key={vt._id}
                        index={i}
                        onClick={(vehicle_type) => {
                          // check auth
                          setClickedVehicleType(vehicle_type);
                          if (isAuthenticated) {
                            applyToTheEquation(vehicle_type);
                          } else {
                            if (isEditBooking) {
                              applyToTheEquation(vehicle_type);
                            } else {
                              setShowModal(true);
                            }
                          }
                        }}
                        vehicle_type={vt}
                        useModal={true}
                        price={vt.price}
                        isEditBooking = {isEditBooking}
                      />
                    </>
                  ))}
                  <LoginModal
                    show={showModal}
                    vehicle_type={clickedVehicleType}
                    handleAfterLogin={(vehicle_type) => {
                      setShowModal(false);
                      applyToTheEquation(vehicle_type);
                    }}
                    handleCloseModal={handleCloseModal}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`container-fluid  ${!isEditBooking && "py-5 "} px-0`}
              id="scroll01"
            >
              {!isEditBooking && (
                <div className="row mb-3 d-flex align-items-center">
                  <div className="col px-0">
                    <hr />
                  </div>
                  <div className="col-auto text-center">
                    <h3>{t("Discover.Heading.Three")}</h3>
                  </div>
                  <div className="col px-0">
                    <hr />
                  </div>
                </div>
              )}
              <div className="row mb-3 d-flex align-items-center justify-content-center">
                <div className="col-md-8 text-center justify-content-center ps-3 pt-3 pb-2 mx-auto">
                  <p>
                    Considering the maximum capacity of our vehicle fleet, which
                    can accommodate{" "}
                    <b className="text-background-highlight">
                      up to 9 individuals,
                    </b>{" "}
                    it will be necessary for you{" "}
                    <span className="text-background-highlight">
                      to make a reservation for <b>two vehicles</b> in order to
                      accommodate your group.
                    </span>
                  </p>
                </div>
              </div>
              {/* <!-- select vehicle heading end --> */}

              {packageList.length > 0 && (
                <div className="row mb-3 align-items-center justify-content-center">
                  <div className="col-10">
                    <div className="card">
                      <div
                        className="card-body bg-gold-light-100 border border-0"
                        style={{ padding: "0.5rem" }}
                      >
                        <p className="card-text font-weight-light text-center">
                          {packageList[0].packagename} is applied
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {
                Array.from({ length: countCarToChoose }, (_, index) => (
                  <CarSelection
                    key={index}
                    index={index}
                    vehicleTypeNames={vehicleTypeNames}
                    applyToTheEquation={applyToTheEquation}
                    applyPaxAndLugageToTheEquation={applyPaxAndLugageToTheEquation}
                    idScroll={"scroll" + (index + 1)}
                    currentMaxPassenger={getCurrentMaxPassenger}
                    currentMaxLugage={getCurrentMaxLugage}
                    clickedManyVehicleType={getClickedManyVehicleType}
                  />
                ))
              }
              <LoginModal
                show={showModal}
                handleAfterLogin={() => {
                  setShowModal(false);
                  setIsEquationApplied(true);
                }}
                handleCloseModal={handleCloseModal}
              />
            </div>
          )}
          {/* <!-- section 1 end --> */}
        </Fragment>
      )}
    </>
  );
};

Discover.propTypes = {
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(withAlert()(Discover));
